import { useLocation } from 'react-router-dom'
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/components/shadcn/popover.js"
import { ScrollArea } from "./shadcn/scroll-area.js"

export function TermsOfService() {
    const location = useLocation();

    return (
        <Popover defaultOpen={location.pathname == '/terms'}>
            <PopoverTrigger asChild>
                <a>
                    Terms of Service
                </a>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col justify-center items-center w-fit">
                <h1>
                    Terms of Service
                </h1>
                <ScrollArea className="h-96" type="auto">
                <ol style={{"listStyle":"decimal","margin":"30px"}}>
                    <li>
                        Acceptance of Terms<br/>
                        By accessing or using the Diplomabot platform (the "Service"),<br/>
                        you agree to be bound by these Terms of Service ("Terms").<br/>
                        If you do not agree to these Terms, you may not use the Service.
                    </li>
                    <li>
                        Description of Service<br/>
                        Diplomabot provides an online platform for playing the strategic<br/>
                        board game Diplomacy, offering various game modes, matchmaking,<br/>
                        and social features. The Service is intended for users aged 13 and older.
                    </li>
                    <li>
                        User Conduct. Users agree to:<br/>
                        <ul style={{"listStyle":"outside","margin":"20px"}}>
                            <li>
                                Engage in fair play
                            </li>
                            <li>
                                Respect other users
                            </li>
                            <li>
                                Not use the Service for illegal activities
                            </li>
                            <li>
                                Not attempt to disrupt or manipulate game mechanics
                            </li>
                        </ul>
                    </li>
                    <li>
                        Intellectual Property<br/>
                        All content on the platform is owned by Diplomabot LLC.<br/>
                        Users retain ownership of their user-generated content.
                    </li>
                    <li>
                        Subscription and Pricing<br/>
                        The Service offers [describe subscription tiers]<br/>
                        Pricing and billing terms are detailed in our separate Pricing Policy.
                    </li>
                    <li>
                        Disclaimer of Warranties<br/>
                        The Service is provided "as is" without guarantees.<br/>
                        We do not warrant uninterrupted or error-free service.<br/>
                        Diplomabot may terminate accounts for violation of Terms or abuse.
                    </li>
                    <li>
                        Limitation of Liability<br/>
                        Diplomabot is not liable for indirect, incidental, or consequential damages.<br/>
                        Total liability is limited to the amount paid for the Service.
                    </li>
                    <li>
                        Privacy<br/>
                        User data is handled according to our Privacy Policy.
                    </li>
                    <li>
                        Dispute Resolution<br/>
                        Any disputes will be resolved through arbitration.
                    </li>
                    <li>
                        Modifications<br/>
                        We may update these Terms, with notice to users.
                    </li>
                </ol>
                Contact for Terms: <a href="mailto:terms@diplomabot.com">terms@diplomabot.com</a>
                </ScrollArea>
                
            </PopoverContent>
        </Popover>
    )
}

export function PrivacyPolicy() {
    return (
        <Popover defaultOpen={location.pathname == '/privacy'}>
            <PopoverTrigger asChild>
                <a>
                    Privacy Policy
                </a>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col justify-center items-center w-fit">
                <h1>
                    Privacy Policy
                </h1>
                <ScrollArea className="h-96" type="auto">
                <ol style={{"listStyle":"decimal","margin":"30px"}}>
                    <li>
                        Information We Collect<br/>
                        Personal Information: Name, Email, Username, Profile<br/>
                        Technical Information: IP address, Device, Browser<br/>
                        Game Information: Usage and Performance Statistics
                    </li>
                    <li>
                        How We Collect Information<br/>
                        Directly from user registration<br/>
                        Automatically through platform usage<br/>
                        Cookies and tracking technologies
                    </li>
                    <li>
                        How We Use Your Information<br/>
                        To provide and improve the Service<br/>
                        To communicate with users<br/>
                        To process payments<br/>
                    </li>
                    <li>
                        Information Sharing<br/>
                        We do not sell personal data to third parties<br/>
                        We may be coerced to privode data to service providers<br/>
                        and governments outside of our control.
                    </li>
                    <li>
                        Children's Privacy<br/>
                        Service not intended for children under 13.
                    </li>
                    <li>
                        International Users<br/>
                        Data may be transferred internationally. <br/>
                        Subject to U.S. data protection laws.
                    </li>
                    <li>
                        Policy Updates<br/>
                        We may update this policy periodically.<br/>
                        Continued use implies acceptance of changes.
                    </li>
                </ol>
                Contact for Privacy Questions: <a href="mailto:privacy@diplomabot.com">privacy@diplomabot.com</a>
                </ScrollArea>
                
            </PopoverContent>
        </Popover>
    )
}