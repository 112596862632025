import { Button } from "@/ui/components/shadcn/button.js"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "@/ui/components/shadcn/tooltip.js"
import { PiggyBankIcon } from 'lucide-react'

export function DonateLink(){
    return (
        <a target="_blank" href='https://donate.stripe.com/7sIg244dr4Ar0HS9AA' rel = "noopener noreferrer">Donate</a>
    )
}

export const DonateButton = () => {

    return <TooltipProvider delayDuration={10}>
        <Tooltip>
        <TooltipTrigger asChild>
            <Button variant="outline" size="icon" onClick={()=>{window.open('https://donate.stripe.com/7sIg244dr4Ar0HS9AA','_blank','noopener,noreferrer')}}>
                <PiggyBankIcon/>
            </Button>
        </TooltipTrigger>
            <TooltipContent>
                Donate
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}
