import React, { useState, useContext }  from 'react';
import ReactDOM from 'react-dom/client'
import { Link, Route, Routes, RouteMatch, BrowserRouter, Navigate } from 'react-router-dom'
import './assets/app.css'
import Home from './pages/home.js';
import Login from './pages/login.js';
import Mail from './pages/mail.js';
import { ThemeProvider } from "@/ui/components/theme-provider.js"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './assets/app.css';

const queryClient = new QueryClient();

function App() {
  const [count, setCount] = useState(0)

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/mail" element={<Mail/>} />
              <Route path="/terms" element={<Home/>} />
              <Route path="/privacy" element={<Home/>} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App;
