
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/ui/components/shadcn/accordion.js"

export function GameInstructions() {
  return (
    <Accordion type="single" collapsible className="w-1/2" defaultValue="item-1">
      <AccordionItem value="item-1">
        <AccordionTrigger>What is Diplomacy?</AccordionTrigger>
        <AccordionContent className="text-start">
        Diplomacy is a classic strategy board game set in pre-World War I Europe between
        Austria-Hungary, England, France, Germany, Italy, Russia, and Turkey. Unlike many war games, Diplomacy emphasizes 
        player interaction, communication, and strategic negotiation over dice rolls or complex combat mechanics.
          The game is unique in that all players moves resolve simultaneously, and success depends entirely on building alliances, 
          making deals, and ultimately outmaneuvering your opponents. There are no random elements; players must balance cooperation and competition, knowing that today's ally could be tomorrow's 
          enemy. The goal is simple: control half the supply centers across the board to win the game. But achieving this requires masterful 
          diplomacy, cunning strategy, and the ability to persuade, manipulate, and sometimes backstab your fellow players.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>Basic Overview</AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-row flex-wrap gap-0 justify-center">
              <div className="basis-1/3 min-w-60">
                  <h4>Game Setup</h4>
                  <ul className="text-start" style={{"listStyle":"outside","margin":"20px"}}>
                      <li>Each player controls one of seven European powers</li>
                      <li>Starting positions are predetermined on the map</li>
                      <li>Each player begins with 3-4 units (fleets and armies)</li>
                      <li>The board is divided into land and sea territories</li>
                  </ul>
              </div>
              <div className="basis-1/3 min-w-60">
                  <h4>Basic Gameplay</h4>
                  <ul className="text-start" style={{"listStyle":"decimal","margin":"20px"}}>
                      <li>Negotiation Phase: Players chat and make deals</li>
                      <li>Order Submission Phase: Before the time limit players submit their moves</li>
                      <li>Arbitration: All moves are publicly revealed and resolved</li>
                      <li>Retreats, Disbandons, Building, etc. are collected and resolved if needed</li>
                  </ul>
              </div>
              <div className="basis-1/3 min-w-60">
                  <h4>Movement Types</h4>
                  <ul className="text-start" style={{"listStyle":"outside","margin":"20px"}}>
                      <li><b>Hold:</b> Stay in current territory</li>
                      <li><b>Move:</b> Advance to an adjacent territory</li>
                      <li><b>Support:</b> Help another unit's movement or defense</li>
                      <li><b>Convoy:</b> Transport armies across water</li>
                  </ul>
              </div>
              <div className="basis-1/3 min-w-60">
                  <h4>Timeframe</h4>
                  <ul className="text-start" style={{"listStyle":"outside","margin":"20px"}}>
                      <li>One season (round) each day or workday</li>
                      <li>Games often run between two and twelve weeks</li>
                  </ul>
              </div>
              <div className="basis-1/3 min-w-60">
                  <h4>Winning</h4>
                  <ul className="text-start" style={{"listStyle":"outside","margin":"20px"}}>
                      <li>Control 18 supply centers</li>
                      <li>All others countries forfeit the game</li>
                  </ul>
              </div>
              <div className="basis-1/3 min-w-60">
                  <h4>Starting Out</h4>
                  <ul className="text-start" style={{"listStyle":"outside","margin":"20px"}}>
                      <li>Ask questions to the game arbitrator bot in your chat environment for help and assistance with rules and strategy.</li>
                      <li>Submit orders privately to the arbitrator for later public arbitration</li>
                      <li>Missing the moderator bot? Install the app</li>
                  </ul>
              </div>
          </div>

        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>Game Manual and Videos</AccordionTrigger>
        <AccordionContent>
          <ul  className="text-start">
            <li><a href="https://media.wizards.com/2015/downloads/ah/diplomacy_rules.pdf" target="_blank">Game Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=vKfAZXVKyyE" target="_blank">How to Play / Rules (YouTube)</a></li>
            <li><a href="https://www.youtube.com/watch?v=duUGPtbxx5g" target="_blank">Beginners Tips (YouTube)</a></li>
          </ul>
        </AccordionContent>
      </AccordionItem>
      {/* <AccordionItem value="item-4">
        <AccordionTrigger>Installation Links</AccordionTrigger>
        <AccordionContent>
          <ul  className="text-start">
            <li><a href="" target="_blank">Slack Marketplace</a></li>
            <li><a href="" target="_blank">Discord</a></li>
          </ul>
        </AccordionContent>
      </AccordionItem> */}
    </Accordion>
  )
}