import * as React from "react"
import { MoonIcon, SunIcon } from "lucide-react"
import { Button } from "@/ui/components/shadcn/button.js"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/ui/components/shadcn/dropdown-menu.js"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/ui/components/shadcn/tooltip.js"
import { useTheme } from "@/ui/components/theme-provider.js"

interface CustomButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  children: React.ReactNode;
}

const ToggleButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} variant="outline" size="icon" ref={ref}>
        {children}
      </Button>
    );
  }
);

export function ModeToggle() {
  const { setTheme } = useTheme()

  return (
    <TooltipProvider delayDuration={10}>
        <Tooltip>
        <DropdownMenu>
        <DropdownMenuTrigger asChild>
        <TooltipTrigger asChild>
            
              <ToggleButton>
                <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                <span className="sr-only">Toggle theme</span>
              </ToggleButton>
            
        </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setTheme("light")}>
                Light
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("dark")}>
                Dark
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme("system")}>
                System
              </DropdownMenuItem>
            </DropdownMenuContent>
            <TooltipContent>
              Theme
            </TooltipContent>
            </DropdownMenu>
        </Tooltip>
    </TooltipProvider>
    
  )
}
